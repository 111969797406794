import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as Sentry from '@sentry/browser';
import configureStore, { history } from "./redux/store";
import 'airbnb-js-shims';

Sentry.init({ dsn: "https://b156124d6ac8425486db9c9ac95e0294@sentry.io/1727875" });

const store = configureStore();

ReactDOM.render(<Provider store={store}>
  <ConnectedRouter history={history}>
    <App store={store} />
  </ConnectedRouter>
</Provider>,
  document.getElementById('root'));

serviceWorker.unregister();
