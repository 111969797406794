import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { updateLanguage, apiGetRequest } from '../redux/actions';
import { API_BASE_URL } from '../const';
import Error from './Error';
import { Helmet } from "react-helmet";
import { Col } from 'react-bootstrap';

class Content extends Component {

  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.renderHelmet = this.renderHelmet.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.fetchData();
    }
  }

  render() {
    if (this.props.error) {
      return <Error />
    }

    return (
      <Fragment>
        {this.renderHelmet()}
        {this.props.children()}
      </Fragment>
    )
  }

  fetchData() {
    const params = {
      content_type: this.props.contentType,
      include: 3,
      'fields.slug[in]': this.props.slug
    };

    const payload = {
      url: API_BASE_URL,
      params: params
    }
    if (this.props.content[this.props.language] === null || typeof (this.props.content[this.props.language]) === 'undefined') {
      this.props.apiGetRequest(payload);
    }
  }

  renderHelmet() {

    const data = this.props.content[this.props.language];
    const seoData = getSEO(data);

    if (data === null || typeof (seoData) === 'undefined' || Object.entries(seoData).length === 0) return <Col />;

    const seo = seoData.fields;
    const { fields } = data.items[0];

    if (seo !== null) {

      return (
        <Helmet>
          <html lang={this.props.language} />
          <title>{fields.siteName}</title>
          <meta name="description" content={seo.metaDescription} />
          <meta name="keywords" content={seo.metaKeywords} />
        </Helmet>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.appState.currentLanguage,
    content: state.appState.contents,
    error: state.appState.error

  }
}

const getSEO = (data) => {
  if (data === null) return {};
  const { fields } = data.items[0];
  const { includes } = data;

  if (typeof (includes) === 'undefined') return {};
  if (fields['seo'] == null) return {};

  const { sys } = fields.seo;
  const seo = includes.Entry.filter(x => x.sys.id === sys.id);

  return seo[0];

}

export default connect(mapStateToProps, { updateLanguage, apiGetRequest })(Content);
