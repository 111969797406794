import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default () => {
  return <Container>
    <Row>
      <Col>
        Error Loading. Please try again later.
      </Col>
    </Row>
  </Container>
}
