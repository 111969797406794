import React from 'react';
import { Col } from 'react-bootstrap';
import './scss/SiteHeading.scss'

export default (props) => {
  const { fields } = props.content.items[0];
  return (
    <div className="SiteHeading">
      <Col className="heading-container">
        <div className="rectangle-copy helvar-merca-profi">
          <p>{fields.siteHeading}</p>
        </div>
      </Col>
    </div>
  )
}
